import { useState } from 'react';
import '../../styles/Home.css';
import '../../styles/SteamId.css';
import STEAMnav from '../../STEAM-Nav';
import Wip from '../../wip';
import steamUserData from '../../data/steamUserData.json';
import parse from 'html-react-parser';

const STEAMID = () => {

  var currentUserId = 0;
  var currentUserName = "";
  var currentUserActivities = [];
  var userActStr = "";
  var actStr = "";
  var bld = "";

  console.log("***********************entered*****************");

  steamUserData.steamUsers.map((user, index) => {

    currentUserId = user.id;
    currentUserName = user.name;
    if (index < 172) {


      if (index % 3 == 0) {
        bld = bld + "<div className=\"row\" style={{ margin: 0px; padding :0px; }}>";
      }

      bld = bld + "<div className=\"col-4\" style={{ margin: 0px; padding: 0px; }}> ";
      bld = bld + "<div className=\"div-img-text\">";
      bld = bld + "<img src=\"/images/steam/id/id-v.png\" alt=\"\" width=\"100%\" className=\"rounded\"></img>";
      bld = bld + "<div className=\"text-on-image\">";
      bld = bld + "<h6 ></h6>";
      bld = bld + "<h6 ></h6>";
      bld = bld + "<img src=\"/images/steam/id/person.jpg\" alt=\"\" width=\"30%\" className=\"rounded\"></img><br>";

      bld = bld + "<h6 ><b >ID : " + currentUserId + "</b></h6> ";

      bld = bld + "<p className=\"cFont15\"><b>" + currentUserName + "</b></p>"
      bld = bld + "<h6 > </h6>";
      //if (currentUserName.split(" ").length > 2) {
      //  bld = bld + "<p className=\"cFont15\"><b>" + currentUserName + "</b></p>"
      // }
      // else {
      //   bld = bld + "<p ><b>" + currentUserName + "</b></p>"
      //   bld = bld + "<h6 ></h6>";
      // }

      bld = bld + "<img src=\"/images/steam/id/" + user.uri + ".png\" alt=\"\" width=\"40%\" className=\"\"></img>";
      bld = bld + "</div></div><p >&nbsp;</div>";

      if (index % 3 == 2) {
        bld = bld + "</div><p>&nbsp</p>";
      }
    }
    //console.log("entered" + index);
  });

  console.log(bld);

  return (



    <div className="container background">



      {parse(bld)}

    </div>


  );
}
export default STEAMID;
